import Link from "next/link";

import styles from "./index/error.page.module.scss";

const Error404 = () => {
  return (
    <div className={styles.errorPage}>
        <main className={"container guttered"}>
            <div className={styles.content}>
                <img src="https://www.freshbox.sk/files/attachment/2020-10/91210/74f64a/avocado.svg" />
                <div className={styles.text}>
                    <h1>404</h1>
                    <h2>Nenašli ste, čo ste hľadali?</h2>
                    <Link href="/" as={`/`}>
                        <a>
                            Späť na domovskú stránku   ›
                        </a>
                    </Link>
                </div>
            </div>
        </main>
    </div>
  );
};

export default Error404;
